//Router
import { createBrowserRouter, Navigate } from "react-router-dom";
import { motion } from "framer-motion";

import LandingPage from "../pages/public/LandingPage/LandingPage"
import Base from "../pages/public/LandingPage/Base";
import VendaInternet from "../pages/public/LandingPage/components/CustomerRegister/VendaInternet";
import BaseLP from "../pages/public/LandingPage/BaseLP";
import CadastroMovel from "../pages/public/Venda/Produtos/TelefoniaMovel/CadastroMovel";
import CadastroInternet from "../pages/public/Venda/Produtos/Internet/CadastroInternet";
import CadastroTelevisao from "../pages/public/Venda/Produtos/Televisao/CadastroTelevisao";
import CadastroFixa from "../pages/public/Venda/Produtos/TelefoniaFixa/CadastroFixa";

const AnimatedRoute = ({ children }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
    >
        {children}
    </motion.div>
);

export const router = createBrowserRouter([

    {
        path: '/',
        element: <Navigate to='/ecommerce/combo/internet/todos' />,
        children: []
    },
    {
        path: '*',
        element: <Navigate to='/ecommerce/combo/internet/todos' />,
        children: []
    },
    // {
    //     path: '/:consultorVenda/user',
    //     element: <Base size="md" />,
    //     children: [
    //         {
    //             path: 'viabilidade/:filtro/:params',
    //             element: <AnimatedRoute><VendaInternet /></AnimatedRoute>,
    //             children: []
    //         },
    //     ]
    // },
    // {
    //     path: '/:consultorVenda/lp',
    //     element: <BaseLP size="md" />,
    //     children: [
    //         {
    //             path: 'comboInternet/:filtro/:params',
    //             element: <AnimatedRoute><VendaInternet /></AnimatedRoute>,
    //             children: []
    //         },
    //     ]
    // },
    // {
    //     path: '/:consultorVenda/:tipoVenda',
    //     element: <Base />,
    //     children: [
    //         {
    //             path: 'internet/:filtro/:origem',
    //             element: <LandingPage filtro="internet" />,
    //             children: []
    //         },
    //         {
    //             path: 'telefoniaMovel/:filtro',
    //             element: <LandingPage filtro="telefoniaMovel" />,
    //             children: []
    //         },
    //         {
    //             path: 'televisao/:filtro',
    //             element: <LandingPage filtro="televisao" />,
    //             children: []
    //         },
    //         {
    //             path: 'telefoniaFixa/:filtro',
    //             element: <LandingPage filtro="telefoniaFixa" />,
    //             children: []
    //         },
    //         {
    //             path: '*',
    //             element: <Navigate to='/ecommerce/combo/internet/todos' />,
    //             children: []
    //         },
    //     ]
    // },
    {
        path: '/:consultorVenda/:tipoVenda',
        element: <BaseLP />,
        children: [
            {
                path: 'internet/:filtro/:idPlano/:origem',
                element: <CadastroInternet />,
                children: []
            },
            // {
            //     path: 'telefoniaMovel/:filtro/:idPlano',
            //     element: <CadastroMovel />,
            //     children: []
            // },
            // {
            //     path: 'televisao/:filtro/:idPlano',
            //     element: <CadastroTelevisao />,
            //     children: []
            // },
            // {
            //     path: 'telefoniaFixa/:filtro/:idPlano',
            //     element: <CadastroFixa />,
            //     children: []
            // },
        ]
    },
]);

