// ~~ React && Router
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

// ~~ MUI
import {  Divider, Grid, Typography } from '@mui/material'

// ~~ Utils
import { postComboById } from '../../../../../utils/planService';
import InternetComboModel from '../../../../../contexts/InternetComboModel';

const CardInternetSelecionado = () => {

  const { setInternetComboObj } = useContext(InternetComboModel)
  const { origem, idPlano, filtro, consultorVenda, tipoVenda } = useParams();
  const [comboData, setComboData] = useState([]);

  useEffect(() => {
    getCombo();
  }, []);

  const getCombo = async () => {

    try {

      const { status, message } = await postComboById(idPlano);

      if (status === 200) {

        setStates(message)

      } else { console.log(message) }

    } catch (error) { console.error(error); }

  };

  const setStates = async (message) => {

    const { name } = JSON.parse(message.observacao1)

    await setComboData(prevState => ({
      ...prevState,
      valor: message.valor.replace('.', ','),
      tittle: name[0].tittle,
      subtittle: name[0].subtittle
    }));

    await setInternetComboObj(prevState => ({
      ...prevState,
      obs2: JSON.parse(message.observacao2),
      combo_id: idPlano,
      pagina: filtro,
      consultorVenda: consultorVenda,
      origem: origem,
    }));

  };

  return (

    <Grid container sx={{ mt: 1, backgroundColor: '#1b1464', width: '1088px', height: '230px', borderRadius: '50px' }} >

      <Grid item xs={12} sm={5.5} sx={{ alignItem: "center", marginY: 'auto' }}>
        <Typography sx={{ textAlign:{ xs: 'center', sm: 'end' }, fontSize:  { xs: '24px', sm: '54px' }, color: '#fff', margin: '0', lineHeight: '1', fontWeight: { xs: 'normal', sm: 'bold' }, letterSpacing: '-2.56px' }}> você </Typography>
        <Typography variant="h1" sx={{ fontWeight:{ xs: 'normal', sm: 'bold' },   textAlign: { xs: 'center', sm: 'end' }, fontSize:  { xs: '24px', sm: '54px' }, color: '#fff', margin: '0', lineHeight: '1', letterSpacing: '-2.56px' }}> selecionou: </Typography>
      </Grid>

      <Grid item xs={0} sm={1} >
        <Divider orientation="vertical" sx={{
          backgroundColor: 'white',
          width: '1px',
          mx: "auto",
          height: '70%',
          marginTop: '30px'

        }} />
      </Grid>

      <Grid item xs={12} sm={5.5} sx={{ marginY: { xs: 'none', sm: 'auto' }, textAlign: { xs: 'center', sm: 'left' } }}  >
        <Typography sx={{ fontSize: '16px', color: '#fff', fontWeight: 'bold', margin: '0', lineHeight: '1.1' }} >
          ({tipoVenda.toUpperCase()})
        </Typography>
        <Typography sx={{ fontSize: '32px', color: '#fff', fontWeight: 'bold', margin: '0', lineHeight: '1.1' }}>
          {comboData.tittle}
        </Typography>
        <Typography sx={{ fontSize: '32px', color: '#fff', fontWeight: 'light', margin: '0', lineHeight: '1.1' }}>
          {comboData.subtittle}
        </Typography>
        <Typography sx={{ fontSize: '24px', color: '#fff', margin: '0', lineHeight: '1.1' }}>
          R$ {comboData.valor}
        </Typography>
      </Grid>


    </Grid>

  )
}

export default CardInternetSelecionado
