import axios from "axios";
import { criarVendaUrl } from "../services/urlApi";

export const handleContratar = async (payload) => {

    console.log('entrou em handleContratar')
    console.log(payload)
   
    let combos = payload.obs2;

    const { url, idFechamento } = criarVendaUrl()
  
    for (var i in combos.planos) { 
  
      if (combos.planos[i].tipo === 'Internet') { 
  
        combos.planos[i].dadosServico.endereco.bairro = payload.bairro ? payload.bairro : 'INTERIOR'; 
        combos.planos[i].dadosServico.endereco.cep = payload.cep ? payload.cep.replace (/\D/g, "") : null; 
        combos.planos[i].dadosServico.endereco.cidade = payload.cidade ? payload.cidade : null;
        combos.planos[i].dadosServico.endereco.complemento = payload.complemento ? payload.complemento : null; 
        combos.planos[i].dadosServico.endereco.estado = payload.estado ? payload.estado : null; 
        combos.planos[i].dadosServico.endereco.latitude = payload.latitude ? payload.latitude : null; 
        combos.planos[i].dadosServico.endereco.logradouro = payload.logradouro ? payload.logradouro : 'INTERIOR'; 
        combos.planos[i].dadosServico.endereco.longitude = payload.longitude ? payload.longitude : null; 
        combos.planos[i].dadosServico.endereco.numeroCasa = payload.numeroCasa ? payload.numeroCasa : null; 
        combos.planos[i].dadosServico.endereco.plusCode = payload.plus_code ? payload.plus_code : null; 
        combos.planos[i].dadosServico.observacao =  payload.observacao ? payload.observacao : null; 
        
      }
  
    }

    let produtosContratados = [{
      Avulsos:[],
      Combos: [combos]
    }]
  
    let produtosInteresse = [{
      Avulsos:[],
      Combos:
        [{
          comboId:payload.combo_id,
          nomeCombo:payload.obs2.nomeCombo,
          viabilidadeEndereco:true,
          aceitouContrato:true
        }]
    }];
     
    var arrayData = payload.dataNasc.split("/"); 
    var dataNascFund = arrayData[2] + "-" + arrayData[1] + "-" + arrayData[0];
  
    const send = {
      nome: payload.nome ? payload.nome : null,
      email: payload.email ? payload.email : null,
      telefone: payload.telefone ? payload.telefone.replace (/\D/g, "") : null,
      outro: "outro25",
      classificacaoPessoaId:"1",
      classificacaoPessoaNome:"Residencial",
      motivoFechamento: idFechamento,
      plusCode: payload.plusCode ? payload.plusCode : null,
      latitude: payload.latitude ? payload.latitude : null,
      longitude: payload.longitude ? payload.longitude : null,
      estado: payload.estado ? payload.estado : null,
      cidade: payload.cidade ? payload.cidade : null,
      bairro: payload.bairro ? payload.bairro : 'INTERIOR',
      logradouro: payload.logradouro ? payload.logradouro : 'INTERIOR',
      cep:  payload.cep ? payload.cep.replace (/\D/g, "") : null,
      complemento: payload.complemento ? payload.complemento : null,
      numeroCasa: payload.numeroCasa ? payload.numeroCasa : null,
      documento: payload.documento ? payload.documento : null,
      tipoPessoa: 'F',
      consultor: payload.consultorVenda ? payload.consultorVenda : "ecommerce" ,
      dtNascimento: dataNascFund,
      promocaoVenda: 'Selecione',
      profileContrato: 1,
      novoTelefoneWhats: true,
      telefoneWhats: payload.telefone ? '55' + payload.telefone.replace (/\D/g, "") : null,
      canalRecebeFatura: payload.recebimentoFatura ? payload.recebimentoFatura : 'whatsapp',
      aceitouContrato: payload.aceite,
      produtosInteresse: produtosInteresse,
      produtosContratados: produtosContratados,
      cupomDesconto: payload.cupom ? payload.cupom : null ,
      fluxo:null,
      idLead: payload.leadId,
      debitoAutomatico:null,
      bancoDebitoAutomatico:null,
      agenciaDebitoAutomatico: null,
      contaDebitoAutomatico: null,
      fidelidade:12,
      equipesOs:7,
      tiposOs:9,
      tecnologiaProjeto:"Selecione",
      origem: payload.origem ? payload.origem : '',

    };
  
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded'};

    console.log(JSON.stringify(send))

    const response = await axios.post( url , JSON.stringify(send) , {headers})
      .catch(function (error) {
        console.log('error : ', error);
      });
      console.log('response', response)
      return { statusVenda:  response.data.status, idVenda: response.data.id_venda }

  }
