import axios from "axios";

export const IntegrarVenda = async (payload) => {

    console.log('  em IntegrarVenda, ', payload)
  
    const send = {
        id_venda: payload.idVenda,
    };
  
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded'};
    console.log(JSON.stringify(send))

    //const response = await axios.post(`http://10.203.0.18:8000/crm/leadLPIntegrarVenda`, JSON.stringify(send) , {headers})
    const response = await axios.post(`https://sgv.copreltelecom.com.br/crm/leadLPIntegrarVenda`, JSON.stringify(send) , {headers})
    .catch(function (error) {
        console.log('error : ', error);
    });
    console.log(response)
    return { statusVenda:  response.data.status, idVenda: response.data.id_venda }

  }