import { getCombosUrl, getSvaDataBaseUrl, postComboByIdUrl } from "../services/urlApi";
import { getAccessToken } from "./tokenService";
import axios from "axios";
  
export const getCombos = async () => {

    const { message } = await getAccessToken();
    const url = getCombosUrl();

    const res = await axios.get(url + message);

    if (res.data.ERROR){
        return { status: 400, message: res.data.ERROR }
    } else if (res.status === 500){
        return { status: 500, message: 'Erro Interno' }
    } else {
        return { status: 200, message: res.data }
    }

};
  
export const postComboById = async (combo_id) => {

    const { message } = await getAccessToken();
    const url = postComboByIdUrl()

    const data = { parametro1: combo_id };
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded'};
    const res = await axios.post(url + message, data, { headers });

    if (res.data.ERROR){
        return { status: 400, message: res.data.ERROR }
    } else if (res.status === 500){
        return { status: 500, message: 'Erro Interno' }
    } else {
        return { status: 200, message: res.data }
    }

};

export const getSvaDataBase = async () => {

    const { message } = await getAccessToken();
    const url = getSvaDataBaseUrl()
    const res = await axios.get(url + message);

    if (res.data.ERROR){
        return { status: 400, message: res.data.ERROR }
    } else if (res.status === 500){
        return { status: 500, message: 'Erro Interno' }
    } else {
        return { status: 200, message: res.data }
    }

};
