import { useState } from "react";
import { createContext } from "react";

const InternetComboModel = createContext();

export const InternetComboModelProvider = ({ children }) =>{

    const [InternetComboObj, setInternetComboObj] = useState({});
    const [DadosVendaObj , setDadosVendaObj] = useState({});

    const apiGoogleToComboObj = async (address_components) => { 

        const logradouro = InternetComboObj.logradouro ? InternetComboObj.logradouro : ( address_components.route ? address_components.route : 'INTERIOR' )
        const bairro = InternetComboObj.bairro ? InternetComboObj.bairro : ( address_components.sublocality ? address_components.sublocality : 'INTERIOR' )
        const cep = InternetComboObj.cep ? InternetComboObj.cep : ( address_components.postal_code ? address_components.postal_code : null )
        const numeroCasa = InternetComboObj.numeroCasa ? InternetComboObj.numeroCasa : ( address_components.street_number ? address_components.street_number : null )
        const latitude = InternetComboObj.latitude ? InternetComboObj.latitude : ( address_components.lat ? address_components.lat : null )
        const longitude = InternetComboObj.longitude ? InternetComboObj.longitude : ( address_components.lng ? address_components.lng : null )
        const plus_code = InternetComboObj.plus_code ? InternetComboObj.plus_code : ( address_components.plus_code ? address_components.plus_code : null )
        
        await setInternetComboObj(prevState => ({
            ...prevState, 
            logradouro: logradouro,
            bairro: bairro,
            cep: cep,
            numeroCasa: numeroCasa,
            latitude: latitude,
            longitude: longitude,
            plus_code: plus_code
        }));

        return true
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDadosVendaObj((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleManualChange = (name, value) => {
        setDadosVendaObj((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    return (
        <InternetComboModel.Provider value={{ InternetComboObj, setInternetComboObj, apiGoogleToComboObj, DadosVendaObj, setDadosVendaObj, handleInputChange, handleManualChange }}>
            {children}
        </InternetComboModel.Provider>
    )
}

export default InternetComboModel;