import { Container, Grid, Typography } from '@mui/material'
import React from 'react'

const PjFeedback = () => {

  return (
    <Container component="main" >

      <Grid item xs={12} sm={12} sx={{ padding: '10px', borderRadius: '10px', mt: 2 }}>
        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'bold', fontSize: '22px' }}>
          Obrigado pelo contato!
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ borderRadius: '10px', mt: 1, marginX: 'auto' }}>
        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'normal', fontSize: '20px' }}>
          Em breve nossa equipe entrará em contato com mais informações.
        </Typography>
      </Grid>

    </Container>
  )
}

export default PjFeedback
