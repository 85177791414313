import { Grid, Typography } from '@mui/material'
import React from 'react'
import SVGlogo from '../../../images/SVGlogo'

const Footer = () => {
  return (
    <Grid id="footerGrid" container sx={{ backgroundColor: '#ebebeb', height: {xs:'300px', sm:'150px'}, borderRadius: '50px', alignContent: 'center',pb:1 ,mb:5 }} >
      <Grid item xs={12} sm={6} sx={{ padding: '20px', textAlign: 'right', mt : 6, display:{xs:'none', sm:'block'}}}>
        <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>
          Coprel Telecom Ltda 
        </Typography>
        <Typography sx={{ color: '#707070',  fontWeight: 'normal', fontSize: '16px' }}>
          Av Brasil, 2530 - Ibirubá_RS_BRASIL 
        </Typography>
        <Typography sx={{ color: '#707070',  fontWeight: 'normal', fontSize: '16px' }}>
          800 039 0039
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} sx={{ padding: '20px', textAlign: 'center', mt : 6,display:{xs:'block', sm:'none'} }}>
        <Typography sx={{ color: '#707070', fontWeight: 'normal', fontSize: '16px' }}>
          Coprel Telecom Ltda  
        </Typography>
        <Typography sx={{ color: '#707070',  fontWeight: 'normal', fontSize: '16px' }}>
        Av Brasil, 2530 - Ibirubá_RS_BRASIL 
        </Typography>
        <Typography sx={{ color: '#707070',  fontWeight: 'normal', fontSize: '16px' }}>
        800 039 0039 
        </Typography>

      </Grid>
      <Grid item xs={12} sm={6} sx={{ padding: '20px', mt: 2, textAlign: { xs:'center', sm:'right'}}}>
        <SVGlogo width='200' height='100' />
      </Grid>

    </Grid>
  )
}

export default Footer

