import axios from "axios";
import { contratarSemViabilidadeUrl, criarLeadComViabilidadeUrl } from "../services/urlApi";

export const ContratarSemViabilidade = async (payload) => {

    const { url } = contratarSemViabilidadeUrl()

    let produtosInteresse = [{
      Avulsos:[],
      Combos:
        [{
          comboId:payload.obs2.comboId,
          nomeCombo:payload.obs2.nomeCombo,
          viabilidadeEndereco:false,
          aceitouContrato:false
        }]
    }];

    const send = {

      nome: payload.nome,
      email: payload.email ? payload.email : null,
      telefone: payload.telefone.replace (/\D/g, ""),
      pagina: payload.pagina,
      origem: payload.origem ? payload.origem : '',
      plusCode: payload.plusCode ? payload.plusCode : null,
      latitude: payload.latitude,
      longitude: payload.longitude,
      estado: payload.estado ? payload.estado : 'RS',
      cidade: payload.cidade ? payload.cidade : null ,
      bairro: payload.bairro ? payload.bairro : 'INTERIOR' ,
      logradouro: payload.logradouro ? payload.logradouro : 'INTERIOR' ,
      cep: payload.cep ?  payload.cep.replace(/\D/g, "") : null,
      complemento: payload.complemento ? payload.complemento : null,
      numeroCasa: payload.numeroCasa ? payload.numeroCasa : 1,
      consultor: payload.consultorVenda ? payload.consultorVenda : "ecommerce" ,
      produtosInteresse: produtosInteresse,
      fluxo: 1

    };

    console.log(JSON.stringify(send))
    console.log(url)

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded'};

    await axios.post( url, JSON.stringify(send) , {headers})
      .then(function (response) {
        console.log('response : ContratarSemViabilidade', response);
      })
      .catch(function (error) {
        console.log('error : ', error);
      });
  
  }

  export const CriarLeadComViabilidade = async (payload) => {

    const { url } = criarLeadComViabilidadeUrl()
    console.log('CriarLeadComViabilidade')
    console.log(payload)

    let produtosInteresse = [{
      Avulsos:[],
      Combos:
        [{
          comboId:payload.obs2.comboId,
          nomeCombo:payload.obs2.nomeCombo,
          viabilidadeEndereco:true,
          aceitouContrato:false
        }]
    }];
  
    const send = {

      nome: payload.nome,
      email: payload.email ? payload.email : null,
      telefone: payload.telefone.replace (/\D/g, ""),
      pagina: payload.pagina,
      plusCode: payload.plus_code ? payload.plus_code : null,
      latitude: payload.latitude,
      longitude: payload.longitude,
      fluxo:{id:2, ordem:0},
      estado: payload.estado ? payload.estado : 'RS',
      cidade: payload.cidade ? payload.cidade : null ,
      bairro: payload.bairro ? payload.bairro : 'INTERIOR' ,
      logradouro: payload.logradouro ? payload.logradouro : 'INTERIOR' ,
      cep: payload.cep ?  payload.cep.replace(/\D/g, "") : null,
      complemento: payload.complemento ? payload.complemento : null,
      numeroCasa: payload.numeroCasa ? payload.numeroCasa : 1,
      consultor: payload.consultorVenda ? payload.consultorVenda : "ecommerce" ,
      produtosInteresse: produtosInteresse,
      origem: payload.origem ? payload.origem : '',
    
    };
  
    console.log(JSON.stringify(send))
  
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded'};
  
    const response = await axios.post(url, JSON.stringify(send) , {headers})
      .catch(function (error) {
        return { leadId : 0 }
      });

    var leadID = 0

    if (response.data.status === 'sucesso'){
      leadID = response.data.leads[0].id 
    }else {
      leadID = response.data.leadId 
    }

    return { leadId : leadID}
  
  }