import { Typography } from '@mui/material'
import React from 'react'

const PossuiVendaFeedback = () => {
  return (
    <>
      <Typography sx={{ mt: 4, mb: 1, textAlign: 'center', fontFamily: 'Montserrat', fontSize: 20, fontWeight: 'bold', color: '#333' }}>
        Já possui venda em aberto...
      </Typography>
      <Typography sx={{ mb: 3, textAlign: 'center', fontFamily: 'Roboto', fontSize: 16, color: '#666' }}>
        Em breve nossa equipe entrará em contato!
      </Typography>
    </>
  )
}

export default PossuiVendaFeedback