import React from 'react';
import { RouterProvider } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import './reset.css'
import { router } from './router/routes';
import './animations.css'
import { InternetComboModelProvider } from './contexts/InternetComboModel';
import { BlackUIControllerProvider } from "./blackUI/contexts";

createRoot(document.getElementById("root")).render(
  <BlackUIControllerProvider>
    <InternetComboModelProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </InternetComboModelProvider>
  </BlackUIControllerProvider>
);
