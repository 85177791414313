import { Box } from '@mui/material'
import React from 'react'

const SVGlogo = ({width, height}) => {
  return (
    <Box sx={{ marginX: "30px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : '100'} height={height ? height : '50'} viewBox="0 0 310.928 150">
        <g id="logo-telecom" transform="translate(-3934.912 -214.872)">
          <g id="Group_67" data-name="Group 67" transform="translate(3934.912 253.193)">
            <g id="Group_65" data-name="Group 65" transform="translate(1.256 0)">
              <path id="Path_143" data-name="Path 143" d="M3958.559,432.3a19.449,19.449,0,1,1,0-38.894,19.163,19.163,0,0,1,9.834,2.588,16.72,16.72,0,0,1,6.655,6.95l-6.876,3.993a9.473,9.473,0,0,0-3.808-4.178,11.3,11.3,0,0,0-5.879-1.516,11.028,11.028,0,0,0-8.17,3.328,12.067,12.067,0,0,0,0,16.563,11.031,11.031,0,0,0,8.17,3.327,11.338,11.338,0,0,0,5.879-1.553,10.145,10.145,0,0,0,3.956-4.14l6.876,3.919a17.587,17.587,0,0,1-6.8,7.024A19.162,19.162,0,0,1,3958.559,432.3Z" transform="translate(-3939.038 -377.366)" fill="#1b1464" />
              <path id="Path_144" data-name="Path 144" d="M4082.644,432.3a18.947,18.947,0,0,1-13.827-5.62,19.636,19.636,0,0,1,0-27.654,19.867,19.867,0,0,1,27.692,0,19.448,19.448,0,0,1-13.865,33.274Zm-8.208-11.091a11.787,11.787,0,0,0,16.416,0,12.154,12.154,0,0,0,0-16.711,11.787,11.787,0,0,0-16.416,0,12.155,12.155,0,0,0,0,16.711Z" transform="translate(-4025.338 -377.366)" fill="#1b1464" />
              <path id="Path_145" data-name="Path 145" d="M4231.2,393.411a17.341,17.341,0,0,1,13.014,5.657,20.363,20.363,0,0,1,0,27.618,17.391,17.391,0,0,1-13.014,5.62q-8.43,0-12.94-6.211v18.555h-7.986V394.372h7.986V399.7Q4222.77,393.412,4231.2,393.411Zm-9.612,27.914a12.058,12.058,0,0,0,16.711,0,12.437,12.437,0,0,0,0-16.933,12.058,12.058,0,0,0-16.711,0,12.433,12.433,0,0,0,0,16.933Z" transform="translate(-4127.68 -377.366)" fill="#1b1464" />
              <path id="Path_146" data-name="Path 146" d="M4366.38,401.259q3.031-6.876,11.387-6.876v8.651a11.844,11.844,0,0,0-7.985,2.182q-3.4,2.478-3.4,8.171V432.02h-7.986V395.048h7.986Z" transform="translate(-4230.696 -378.042)" fill="#1b1464" />
              <path id="Path_147" data-name="Path 147" d="M4432.555,416.259a10.151,10.151,0,0,0,4.067,6.47,13.065,13.065,0,0,0,7.764,2.256q6.359,0,9.539-4.585l6.581,3.845q-5.474,8.06-16.193,8.06-9.023,0-14.567-5.509a18.821,18.821,0,0,1-5.546-13.938,19.024,19.024,0,0,1,5.472-13.864,18.817,18.817,0,0,1,14.049-5.583,17.291,17.291,0,0,1,13.347,5.693,19.742,19.742,0,0,1,5.212,13.827,24.892,24.892,0,0,1-.295,3.328Zm-.074-6.506h21.738a10.278,10.278,0,0,0-10.573-9.1,11.231,11.231,0,0,0-7.394,2.44A11,11,0,0,0,4432.481,409.753Z" transform="translate(-4276.462 -377.366)" fill="#1b1464" />
              <path id="Path_148" data-name="Path 148" d="M4567.464,394.7V340.719h7.986V394.7Z" transform="translate(-4376.101 -340.719)" fill="#1b1464" />
            </g>
            <g id="Group_66" data-name="Group 66" transform="translate(0 58.222)">
              <path id="Path_149" data-name="Path 149" d="M3958.869,566.943H3949.7v17.746a4.424,4.424,0,0,0,1.035,3.291,4.64,4.64,0,0,0,3.068,1.109,50.318,50.318,0,0,0,5.065-.037v7.172q-9.169,1.11-13.162-1.59t-3.993-9.945V566.943h-6.8v-7.69h6.8v-7.985l7.986-2.366v10.352h9.169Z" transform="translate(-3934.912 -543.729)" fill="#00bdbf" />
              <path id="Path_150" data-name="Path 150" d="M4029.225,602.586a10.149,10.149,0,0,0,4.067,6.47,13.065,13.065,0,0,0,7.764,2.255q6.358,0,9.539-4.585l6.581,3.845q-5.473,8.061-16.194,8.06-9.022,0-14.567-5.509a18.821,18.821,0,0,1-5.546-13.938,19.022,19.022,0,0,1,5.472-13.864,18.819,18.819,0,0,1,14.049-5.583,17.29,17.29,0,0,1,13.347,5.694,19.737,19.737,0,0,1,5.213,13.827,24.821,24.821,0,0,1-.3,3.327Zm-.075-6.506h21.739a10.279,10.279,0,0,0-10.574-9.095,11.227,11.227,0,0,0-7.394,2.44A11,11,0,0,0,4029.15,596.08Z" transform="translate(-3994.694 -565.176)" fill="#00bdbf" />
              <path id="Path_151" data-name="Path 151" d="M4164.135,584.413V531.918h7.985v52.495Z" transform="translate(-4094.334 -531.918)" fill="#00bdbf" />
              <path id="Path_152" data-name="Path 152" d="M4217.406,602.586a10.154,10.154,0,0,0,4.067,6.47,13.065,13.065,0,0,0,7.764,2.255q6.359,0,9.539-4.585l6.581,3.845q-5.473,8.061-16.193,8.06-9.023,0-14.567-5.509a18.819,18.819,0,0,1-5.546-13.938,19.024,19.024,0,0,1,5.472-13.864,18.819,18.819,0,0,1,14.049-5.583,17.289,17.289,0,0,1,13.347,5.694,19.737,19.737,0,0,1,5.213,13.827,24.888,24.888,0,0,1-.295,3.327Zm-.074-6.506h21.738a10.277,10.277,0,0,0-10.573-9.095,11.226,11.226,0,0,0-7.394,2.44A10.993,10.993,0,0,0,4217.333,596.08Z" transform="translate(-4125.573 -565.176)" fill="#00bdbf" />
              <path id="Path_153" data-name="Path 153" d="M4364.309,618.632a19.449,19.449,0,1,1,0-38.894,19.166,19.166,0,0,1,9.834,2.588,16.72,16.72,0,0,1,6.655,6.95l-6.876,3.993a9.472,9.472,0,0,0-3.808-4.178,11.3,11.3,0,0,0-5.879-1.516,11.026,11.026,0,0,0-8.17,3.327,12.066,12.066,0,0,0,0,16.563,11.029,11.029,0,0,0,8.17,3.327,11.335,11.335,0,0,0,5.879-1.553,10.14,10.14,0,0,0,3.956-4.14l6.876,3.919a17.593,17.593,0,0,1-6.8,7.025A19.165,19.165,0,0,1,4364.309,618.632Z" transform="translate(-4219.977 -565.176)" fill="#00bdbf" />
              <path id="Path_154" data-name="Path 154" d="M4488.394,618.632a18.948,18.948,0,0,1-13.827-5.62,19.638,19.638,0,0,1,0-27.654,19.867,19.867,0,0,1,27.692,0,19.448,19.448,0,0,1-13.865,33.275Zm-8.207-11.091a11.786,11.786,0,0,0,16.415,0,12.153,12.153,0,0,0,0-16.711,11.786,11.786,0,0,0-16.415,0,12.153,12.153,0,0,0,0,16.711Z" transform="translate(-4306.276 -565.176)" fill="#00bdbf" />
              <path id="Path_155" data-name="Path 155" d="M4656.4,579.738a13.3,13.3,0,0,1,10.13,4.067q3.844,4.068,3.845,10.943v22.922h-7.985v-22.4a8.52,8.52,0,0,0-1.923-5.953,6.753,6.753,0,0,0-5.249-2.107,7.49,7.49,0,0,0-5.878,2.44q-2.182,2.441-2.182,7.321v20.7h-7.986v-22.4a8.861,8.861,0,0,0-1.811-5.953,6.414,6.414,0,0,0-5.139-2.107,7.7,7.7,0,0,0-5.916,2.477q-2.292,2.479-2.292,7.284v20.7h-7.985V580.7h7.985v4.437q3.549-5.4,10.574-5.4,7.1,0,10.5,5.842Q4648.78,579.74,4656.4,579.738Z" transform="translate(-4408.619 -565.176)" fill="#00bdbf" />
            </g>
          </g>
          <g id="Group_69" data-name="Group 69" transform="translate(4153.541 214.872)">
            <g id="Group_68" data-name="Group 68" transform="translate(0)">
              <path id="Path_156" data-name="Path 156" d="M4745.171,260.247c.424,25.805-20.96,46.925-46.769,46.925h-40.976a4.549,4.549,0,0,1-4.548-4.549V261.647c0-25.809,21.119-47.194,46.923-46.769a46.151,46.151,0,0,1,45.37,45.369" transform="translate(-4652.878 -214.872)" fill="#1b1464" />
            </g>
            <path id="Path_157" data-name="Path 157" d="M4744.315,293.459v-10.54a.868.868,0,0,0-.887-.872c-.432.015-1.47.04-1.9.075a25.712,25.712,0,0,0-.449,51.2c.572.058,1.761.1,2.344.117a.868.868,0,0,0,.887-.872v-10.54a.859.859,0,0,0-.793-.866,23.213,23.213,0,0,1-2.545-.255,13.442,13.442,0,0,1-.077-26.306,22.753,22.753,0,0,1,2.622-.271A.859.859,0,0,0,4744.315,293.459Z" transform="translate(-4698.166 -261.591)" fill="#fed400" />
            <circle id="Ellipse_5" data-name="Ellipse 5" cx="6.078" cy="6.078" r="6.078" transform="translate(51.72 20.308)" fill="#00bdbf" />
            <circle id="Ellipse_6" data-name="Ellipse 6" cx="6.078" cy="6.078" r="6.078" transform="translate(51.72 59.901)" fill="#fc0089" />
          </g>
        </g>
      </svg>
    </Box>
  )
}

export default SVGlogo
