import React, { useContext, useState } from 'react'
import { Autocomplete, Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import MapWithSearchBox from './MapWithSearchBox';
import MaskedInput from 'react-text-mask'
import { useJsApiLoader } from "@react-google-maps/api";
import estados from '../../../../../../../data/estados';

import { useRef } from 'react';
import { enderecoFramentado } from '../../../../../../../utils/enderecoFragmentado';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import axios from 'axios';
import { useEffect } from 'react';
import InternetComboModel from '../../../../../../../contexts/InternetComboModel';
import { addresssStringToObj } from '../../../../../../../services/googleAPI';

const Endereco = ({ handleNext, setOpen }) => {

    const [address, setAddress] = useState([]);
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [openMap, setOpenMap] = useState(false);
    const [personType, setPersonType] = useState('residencia');

    const [selectedEstado, setSelectedEstado] = useState('RS');
    const [cidades, setCidades] = useState([]);
    const { InternetComboObj, setInternetComboObj, apiGoogleToComboObj } = useContext(InternetComboModel)
    const [selectedCidade, setSelectedCidade] = useState(null);

    const logradouroRef = useRef(null);
    const addressNumberRef = useRef(null);
    const bairroRef = useRef(null);

    const [addressVerified, setAddressVerified] = useState(false);

    const handleChangeState = (event) => { setSelectedEstado(event.target.value); };

    const handleChangeCidade = (event, newValue) => {
        setSelectedCidade(newValue);
    };

    const handleChange = (event, newAlignment) => {

        if (newAlignment !== personType && newAlignment !== null) {
            setPersonType(newAlignment);
        }

    };

    const phoneMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,];

    const [buttonHovered, setButtonHovered] = React.useState(false);

    const sxButton = {
        borderRadius: '32px',
        padding: '2px 2px 2px 14px',
        backgroundColor: buttonHovered ? 'white !important' : '#1b1464 !important',
        color: buttonHovered ? '#1b1464 !important' : 'white !important',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#EBEBEB  !important',
            color: '#1b1464 !important',
            '& $arrowIcon': {
                transform: 'rotate(0deg) !important',
            },
        },
    };

    const sxIcon = {
        marginLeft: '8px',
        padding: '8px',
        borderRadius: '50%',
        backgroundColor: buttonHovered ? '#1b1464 !important' : 'white !important',
        color: buttonHovered ? 'white !important' : '#1b1464 !important',
        transform: `rotate(${buttonHovered ? '0deg' : '-45deg'}) !important`,
        transition: 'transform 0.3s',
    }

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAlOpDojufaUXvGgue7c0CoLdHQvZ8Xm3A',
        // libraries:['places']
    })

    useEffect(() => {
        setOpen(true)
        setSelectedCidade(null)
        if (selectedEstado) {
            axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedEstado}/municipios`)
                .then(response => {
                    const listaDeCidades = response.data.map(cidade => ({
                        nome: cidade.nome,
                    }));
                    setCidades(listaDeCidades);
                })
                .catch(error => {
                    console.error('Erro ao obter lista de cidades:', error);
                });
        }
        setOpen(false)
    }, [selectedEstado]);

    useEffect(() => {

        if (InternetComboObj.step === "marcadorMapa") {
            setOpenMap(true)
            setAddressVerified(true)
        } else if (InternetComboObj.step === "handleNext") {
            handleNext(personType, latitude, longitude)
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }

    }, [InternetComboObj]);

    useEffect(() => {

        if (selectedCidade == null && InternetComboObj.cidade !== null && InternetComboObj.cidade !== undefined) {
            const cidade = { nome: InternetComboObj.cidade }
            setSelectedCidade(cidade)
        }

    }, [InternetComboObj.cidade]);

    const aoSalvar = async (evento) => {

        evento.preventDefault();
        const data = new FormData(evento.currentTarget);

        await apiGoogleToComboObj(address)

        setInternetComboObj(prevState => ({
            ...prevState,
            nome: data.get('nome'),
            telefone: data.get('telefone'),
            email: data.get('email'),
            complemento: data.get('complemento'),
            personType: personType,
            step: "handleNext",
        }));

    }

    const buttonVerificarEndereco = async () => {

        const address_components = await addresssStringToObj(`Brasil ${selectedEstado}, ${selectedCidade.nome} ${logradouroRef.current.value} ${addressNumberRef.current.value}`);
        setAddress(address_components)
        setLatitude(address_components.lat)
        setLongitude(address_components.lng)
        console.log('buttonVerificarEndereco', address_components);

        await apiGoogleToComboObj(address_components)

        setInternetComboObj(prevState => ({
            ...prevState,
            estado: selectedEstado,
            cidade: selectedCidade.nome,
            step: "marcadorMapa"
        }));

    }

    return (
        <>
            <form onSubmit={aoSalvar} id='form-new'>
                <Grid container sx={{ mt: 4 }} >
                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <TextField label="Nome Completo" type="Nome" variant="outlined" name="nome"
                            defaultValue={InternetComboObj.nome} required fullWidth InputProps={{ style: { borderRadius: "32px" } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <MaskedInput mask={phoneMask} label="Telefone" defaultValue={InternetComboObj.telefone} name="telefone" id="my-input-id" guide={false}
                            render={(ref, props) => (
                                <TextField variant="outlined" required fullWidth inputRef={ref} InputProps={{ style: { borderRadius: "32px" } }}  {...props} />
                            )}
                            inputProps={{ minLength: 15 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ padding: '10px' }}>
                        <TextField label="Email" type="Email" name="email" defaultValue={InternetComboObj.email} variant="outlined" fullWidth
                            InputProps={{ style: { borderRadius: "32px" } }} />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ padding: '10px' }}>
                        <Typography sx={{ fontWeight: 'bold', color: '#343a40', textAlign: 'center', fontSize: '24px' }}>
                            Para sua residência ou para seu negócio?
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} alignItems='center'>

                        <ToggleButtonGroup
                            sx={{ paddingX: '10px', paddingY: '10px', width: '100%' }}
                            color="primary" value={personType}
                            exclusive fullWidth onChange={handleChange}
                            aria-label="Platform" >

                            <ToggleButton value="residencia" sx={{ borderRadius: '32px' }}>
                                Minha Residência
                            </ToggleButton>
                            <ToggleButton value="negocio" sx={{ borderRadius: '32px' }}>
                                Meu Negócio
                            </ToggleButton>

                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ padding: '10px', mt: 2 }}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Estado</InputLabel>
                            <Select label="Estado" value={selectedEstado} onChange={handleChangeState} disabled={addressVerified} style={{ borderRadius: '32px' }} >
                                {estados.map((estado) => (
                                    <MenuItem key={estado.sigla} value={estado.sigla}>
                                        {estado.nome}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ padding: '10px', mt: 2 }}>
                        <Autocomplete
                            onChange={handleChangeCidade}
                            value={selectedCidade !== null ? selectedCidade : null}
                            options={cidades || []}
                            getOptionLabel={(option) => option.nome}
                            fullWidth
                            sx={{ '& fieldset': { borderRadius: 33 } }}
                            disabled={addressVerified}
                            renderInput={(params) => <TextField {...params} label="Cidade" variant="outlined" required />}
                        />
                    </Grid>

                    <Grid item xs={12} sm={8} sx={{ padding: '10px' }}>
                        <TextField label="Rua" type="text" name="endereco" variant="outlined" disabled={addressVerified} fullWidth inputRef={logradouroRef}
                            inputProps={{ maxLength: 50, }} defaultValue={InternetComboObj.logradouro ? InternetComboObj.logradouro : null}
                            sx={{ '& fieldset': { borderRadius: 33 } }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} sx={{ padding: '10px' }}>
                        <TextField label="Bairro" type="text" name="bairro" variant="outlined" disabled={addressVerified} fullWidth inputRef={bairroRef}
                            inputProps={{ maxLength: 50, }} defaultValue={InternetComboObj.bairro ? InternetComboObj.bairro : null}
                            sx={{ '& fieldset': { borderRadius: 33 } }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <TextField label="Número" type="number" name="address_number" variant="outlined" disabled={addressVerified} fullWidth inputRef={addressNumberRef}
                            inputProps={{ maxLength: 50 }} defaultValue={InternetComboObj.numeroCasa ? InternetComboObj.numeroCasa : null}
                            sx={{ '& fieldset': { borderRadius: 33 } }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <TextField label="Complemento" type="text" name="complemento" variant="outlined" fullWidth
                            defaultValue={InternetComboObj.complemento ? InternetComboObj.complemento : null}
                            sx={{ '& fieldset': { borderRadius: 33 } }}
                            inputProps={{ maxLength: 50 }} />

                    </Grid>

                </Grid>
                <br />

                {(openMap && isLoaded) &&
                    <MapWithSearchBox
                        latitude={InternetComboObj.latitude} setLatitude={setLatitude}
                        longitude={InternetComboObj.longitude} setLongitude={setLongitude}
                        address={address} setAddress={setAddress}
                    />
                }

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 5, justifyContent: 'center' }}>
                    {(addressVerified) ? (

                        <Button
                            variant="contained"
                            sx={sxButton}
                            onMouseEnter={() => setButtonHovered(true)}
                            onMouseLeave={() => setButtonHovered(false)}
                            type='submit'
                        >
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }}>
                                Continuar
                            </Typography>

                            <IconButton
                                className="arrowIcon"
                                type='submit'
                                sx={sxIcon}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Button>
                    ) : (

                        <Button
                            variant="contained"
                            sx={sxButton}
                            onMouseEnter={() => setButtonHovered(true)}
                            onMouseLeave={() => setButtonHovered(false)}
                            disabled={selectedCidade == undefined}
                        >
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }} onClick={buttonVerificarEndereco}>
                                Verificar Endereço
                            </Typography>

                            <IconButton
                                className="arrowIcon"
                                onClick={buttonVerificarEndereco}
                                sx={sxIcon}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Button>
                    )}
                </Box>

            </form>

            {/* <ChatbotComponent /> */}
        </>
    )
}

export default Endereco