//React
import { useState } from 'react';
import PropTypes from 'prop-types';
import theme from '../../../data/theme';

// Material UI
import { ThemeProvider } from '@mui/material/styles';
import {
  Drawer, Divider, CssBaseline, Box, AppBar, Container,
  ListItemButton, Button, Typography, Toolbar, ListItemText, ListItem, List, IconButton, ButtonGroup, Grid
} from '@mui/material';

// Material UI Icons
import MenuIcon from '@mui/icons-material/Menu';

// Router 
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import ChatbotComponent from '../components/ChatBot/ChatbotComponent';
import SVGlogo from '../../../images/SVGlogo';

const drawerWidth = '20%';

function BaseLP(props) {

  const { consultorVenda } = useParams();
  const location = useLocation();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => { setMobileOpen((prevState) => !prevState) }

  const routes = [
    { id: "internet", identifier: "internet", text: "INTERNET", path: "/" + consultorVenda + "/combo/internet/todos" },
    { id: "televisão", identifier: "televisao", text: "TELEVISÃO", path: "/" + consultorVenda + "/plano/televisao/todos" },
    { id: "telFixo", identifier: "telefoniaFixa", text: "TELEFONE FIXO", path: "/" + consultorVenda + "/plano/telefoniaFixa/todos" },
    { id: "telMovel", identifier: "telefoniaMovel", text: "TELEFONE MOVEL", path: "/" + consultorVenda + "/plano/telefoniaMovel/todos" },
  ];

  //Mobile version
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Coprel Telecom
      </Typography>
      <Divider />
      <List>
        {routes.map(({ id, text, path }) => (
          <ListItem key={id} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} as={Link} to={path}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (

    <ThemeProvider theme={theme}>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" >

          <Toolbar sx={{ bgcolor: '#f2f2f2', justifyContent: "space-between" }} >

            <Box sx={{ marginX: "30px" }}>
              <SVGlogo />
            </Box>

          </Toolbar>
        </AppBar>

        <Box component="nav">
          <Drawer
            container={container} variant="temporary"
            open={mobileOpen} onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true, }}
            anchor="right"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "60%" } }}
          >
            {drawer}
          </Drawer>
        </Box>

        <Outlet />
        
      </Box>

      {/* <ChatbotComponent /> */}
    </ThemeProvider>

  );

}

BaseLP.propTypes = {
  window: PropTypes.func,
};

export default BaseLP;
