import { Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import markerIcon from '../images/markerCoprel.png'
const MapWithSearchBox = ({ latitude, setLatitude, longitude, setLongitude, setAddress }) => {

  const markerRef = useRef(null);

  useEffect(() => {
    const initAutocomplete = () => {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: {
          lat: latitude ? latitude : -28.6306,
          lng: longitude ? longitude : -53.0894
        },
        zoom: 16.2,
        mapTypeId: "roadmap",
        streetViewControl: false,
        fullscreenControl: false,
        language: "pt-BR",
      });

      const geocoder = new window.google.maps.Geocoder();
      // const latlng = new window.google.maps.LatLng(
      //   latitude ? latitude : -28.6306,
      //   longitude ? longitude : -53.0894
      // );

      // geocoder.geocode({ location: latlng }, (results, status) => {
      //   if (status === "OK" && results[0]) {
      //     setAddress(results[0].address_components);
      //   }
      // });

      const marker = new window.google.maps.Marker({
        position: {
          lat: latitude ? latitude : -28.6306,
          lng: longitude ? longitude : -53.0894
        },
        map: map,
        draggable: true,
        title: "Marcador Arrastável",
        icon: {
          url: markerIcon,
          scaledSize: new window.google.maps.Size(65, 65),
        },
      });

      markerRef.current = marker;

      window.google.maps.event.addListener(marker, "dragend", function () {
        const latlng = marker.getPosition();
        geocoder.geocode({ location: latlng }, (results, status) => {
          const endereco_fragmentado = {};
          if (status === "OK" && results[0]) {
            setLatitude(latlng.lat());
            setLongitude(latlng.lng());
            results[0].address_components.forEach(componente => {
              componente.types.forEach(tipo => { endereco_fragmentado[tipo] = componente.long_name; });
            });
            setAddress(endereco_fragmentado);
          }
        });
      });

      map.setOptions({
        mapTypeControlOptions: {
          mapTypeIds: ['satellite', 'roadmap'],
          position: window.google.maps.ControlPosition.BOTTOM_LEFT
        },
      });
    };

    if (typeof window !== "undefined" && window.google) {
      initAutocomplete();
    }
  }, []);

  return (
    <>

      <Typography variant="h1" fontWeight="bold" sx={{ color: '#6C757D', fontSize: '16px', margin: '0', lineHeight: '1', ml: 4, mb: 2 }}>
        Digite o endereço ou arraste o marcador no mapa.
      </Typography>


      <div style={{ display: "flex", justifyContent: "center" }}>
        <div id="map" style={{ height: "400px", width: "100%", borderRadius: 30, elevation: 2, border: '4px solid #ffffff' }}></div>
      </div>

    </>

  );
};

export default MapWithSearchBox;
