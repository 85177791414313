import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2A307D',
    },
    secondary: {
      main: '#5E6193',
    },
    pink: {
      main: '#F61C87',
    },
    blue: {
      main: '#00D3CB',
    },
    yellow: {
      main: '#FFD301',
    },
    background: {
      default: 'rgb(255, 255, 255)',
    },
  },
  typography: {
    fontFamily: [
      'Space Grotesk', // Adiciona a fonte desejada
    ].join(','),
  },
});

export default theme;
