import api_coprel from "../services/axiosCreate/api_coprel";
import { postVerificarTicketAbertoUrl, postVerificarViabilidadeUrl } from "../services/urlApi";
import { getAccessToken } from "./tokenService";

const postVerificarViabilidade = async (lat, long) => {

  const { message } = await getAccessToken();

  const url = postVerificarViabilidadeUrl();
  const data = { parametro1: lat, parametro2: long };
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded'};
  const res = await api_coprel.post(url + message, data, { headers });

  if (res.data.ERROR){
    return { status: 400, message: res.data.ERROR }
  } else if (res.status === 500){
    return { status: 500, message: 'Erro Interno' }
  } else {
    return { status: 200, message: res.data }
  }

};

//801.981.980-00
const postVerificarTicketAberto = async (payload) => {

  const { message } = await getAccessToken();
  const url = postVerificarTicketAbertoUrl();
  
  const data = { 
    parametro1: payload.documento, 
    parametro2: payload.estado, 
    parametro3: payload.cidade,
    parametro4: payload.numeroCasa,
  };
  
  console.log('postVerificarTicketAberto: ', data);

  const headers = { 'Content-Type': 'application/x-www-form-urlencoded'};
  const res = await api_coprel.post(url + message, data, { headers });
  console.log('res: ', res);
  if (res.data.ERROR){
    return { status: 400, message: res.data.ERROR }
  } else if (res.status === 500){
    return { status: 500, message: 'Erro Interno' }
  } else {
    return { 
      status: res.data['status'] === 'S' ? true : false, 
      message: JSON.parse(res.data['atendimentos']) 
    }
  }

};

export {
  postVerificarViabilidade,
  postVerificarTicketAberto
}