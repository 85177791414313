import api_coprel from "../services/axiosCreate/api_coprel";

export const getAccessToken = async () => {

    try {
      const res = await api_coprel.get('/auth/ecomerce/ainuxynug127652@nig679n!udfgASFf');
  
      if (res.data.SUCCESS) {
        return { status: 200, message: res.data.TOKEN };
      } else {
        return { status: 401, message: 'Token não Autorizado' };
      }
  
    } catch (error) {
      return { status: 500, message: 'Erro Interno' };
    }
  
  }