import React, { useContext, useEffect, useState } from 'react'
import InternetComboModel from '../../../../../../contexts/InternetComboModel';
import { getCombos } from '../../../../../../utils/planService';
import CardInternet from './CardInternet';

const ServicoInternet = ({ planos }) => {

  const { setInternetComboObj } = useContext(InternetComboModel)

  useEffect(() => {
    setInternetComboObj([])
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'flex-start' }}>
      {planos && planos.length > 0 ? planos.map(data => {
        console.log(data)
        return (
          <div style={{ flex: '1', minWidth: '300px', maxWidth: '350px', margin: '10px', marginBottom: '30px' }}>
            <CardInternet id={data.id} {...data} />
          </div>
        )
      }) : ''
      }
    </div>
  )

}

export default ServicoInternet