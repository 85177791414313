//   PROD   //   DEV   //
const ENVIRONMENT = 'PROD'

const getCombosUrl = () => {
    if (ENVIRONMENT === 'PROD') {
        return "https://api.copreltelecom.com.br/api4/semparametrojson/sgv/combos-sgv/"
    } else {
        return "https://api.copreltelecom.com.br/api4/semparametrojson/sgv_dev/combos-sgv/"
    }
}

const postComboByIdUrl = () => {
    if (ENVIRONMENT === 'PROD') {
        return "https://api.copreltelecom.com.br/api4/parametrospost/sgv/1/combo-por-id/"
    } else {
        return "https://api.copreltelecom.com.br/api4/parametrospost/sgv_dev/1/combo-por-id/"
    }
}

const getSvaDataBaseUrl = () => { return "https://api.copreltelecom.com.br/api4/semparametrojson/customer/sva-db-ecommerce/" }

const postVerificarViabilidadeUrl = () => { return "https://api.copreltelecom.com.br/api4/parametrospost/customer/2/disponibilidade-ecomerce/" }

const postVerificarTicketAbertoUrl = () => { return "https://api.copreltelecom.com.br/api4/parametrospost/customer/1/verificar-ticket-venda-ecommerce/" }

const contratarSemViabilidadeUrl = () => {

    if (ENVIRONMENT === 'PROD') {
        return { url: "https://sgv.copreltelecom.com.br/crm/leadLPJson/somenteInteresse" }

    } else {
        return { url: "http://10.203.0.18:8000/crm/leadLPJson/somenteInteresse" }

    }

}

const criarLeadComViabilidadeUrl = () => {

    if (ENVIRONMENT === 'PROD') {
        return { url: "https://sgv.copreltelecom.com.br/crm/leadLPJson/comViabilidade" }

    } else {
        return { url: "http://10.203.0.18:8000/crm/leadLPJson/comViabilidade" }

    }

}

const criarVendaUrl = () => {

    if (ENVIRONMENT === 'PROD') {

        return {
            url: "https://sgv.copreltelecom.com.br/crm/leadLPJson/criarVenda",
            idFechamento: 19
        }

    } else {

        return {
            url: "http://10.203.0.18:8000/crm/leadLPJson/criarVenda",
            idFechamento: 1
        }

    }

}

export {
    getCombosUrl,
    postComboByIdUrl,
    getSvaDataBaseUrl,
    contratarSemViabilidadeUrl,
    criarLeadComViabilidadeUrl,
    postVerificarViabilidadeUrl,
    postVerificarTicketAbertoUrl,
    criarVendaUrl
}