// React
import { useEffect, useContext, useState } from 'react';
import * as React from 'react';

// Components
import { ComViabilidade, SemViabilidade, PossuiVendaFeedback, PjFeedback } from './feedback';
import { Box, Stepper, Step, StepLabel, Typography, Grid, Container } from '@mui/material';
import InternetComboModel from '../../../../../contexts/InternetComboModel';
import CardInternetSelecionado from './CardInternetSelecionado';
import SimpleBackdrop from '../../../components/SimpleBackdrop';
import { Endereco, Cadastro } from './steps'

// Utils
import { ContratarSemViabilidade, CriarLeadComViabilidade } from '../../../../../utils/apiCriarLead';
import { postVerificarTicketAberto, postVerificarViabilidade } from '../../../../../utils/verifies';
import { IntegrarVenda } from '../../../../../utils/apiIntegrarVenda';
import { handleContratar } from '../../../../../utils/apiCriarVenda';
import Footer from '../../../LandingPage/Footer';

const steps = ['Endereço', 'Cadastro', 'FeedBack'];

const CadastroInternet = () => {

  const [activeStep, setActiveStep] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [contratado, setContratado] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [open, setOpen] = useState(false);

  const { InternetComboObj, setInternetComboObj } = useContext(InternetComboModel)

  const isStepSkipped = (step) => { return skipped.has(step); };

  const skip = (skip_number) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + skip_number);
    setSkipped(newSkipped);
  };

  useEffect(() => {

    if (InternetComboObj.step === "posPreLeadComViabilidade") {
      skip(1)
    }
    // else if (InternetComboObj.step === "handleNext"){
    //   handleNext()
    // }

  }, [InternetComboObj]);

  const verificarEndereco = async (latitude, longitude) => {

    const { status, message } = await postVerificarViabilidade(latitude, longitude);

    if (message.disponibilidade === 'S') {
      return true;
    } else {
      return false;
    }

  }

  const handleNext = async () => {
    console.log("handleNext")

    setOpen(true)
    setContratado(false)

    if (InternetComboObj.personType === "residencia") {

      const res = await verificarEndereco(InternetComboObj.latitude, InternetComboObj.longitude)

      if (res) {

        await preLead()
        setOpen(false)

      } else {
        console.log('criar lead semViabilidade')
        await criarLead('semViabilidade')
        setOpen(false)
      }

    } else {
      setOpen(false)
      console.log('criar lead pj')
      await criarLead('pj')
    }

  }

  const preLead = async () => {

    const res = await CriarLeadComViabilidade(InternetComboObj);
    console.log('res CriarLeadComViabilidade', res);
    await setInternetComboObj(prevState => ({
      ...prevState,
      leadId: res.leadId,
      step: 'posPreLeadComViabilidade'
    }));

  }

  const criarLead = async (entrada) => {

    await ContratarSemViabilidade(InternetComboObj);

    if (entrada === "pj") {
      setFeedback("pj")

    } else {
      setFeedback("semViabilidade")

    }

    setOpen(false)
    skip(2)

  }

  const handleFinish = async () => {

    console.log('aqui ?')
    setOpen(true)

    const verifiedTicket = await postVerificarTicketAberto(InternetComboObj)

    setOpen(false)
    if (verifiedTicket.status) {
      setFeedback('ticketAberto')
      skip(1)
    } else {
      setFeedback('contratar')
      skip(1)
    }


  };

  const contratar = async () => {

    setOpen(true)

    const resCont = await handleContratar(InternetComboObj);

    setOpen(false)
    setContratado(true)

    await IntegrarVenda(resCont);

  }

  const handleBack = async () => {
    skip(-1)
    setInternetComboObj(prevState => ({
      ...prevState,
      step: "back",
    }));
  };

  return (

    <Container component="main" maxWidth="md" sx={{ flexGrow: 1, height: '90vh', marginTop: '10vh', paddingTop: '20px' }}>
      {/* <button onClick={() => { console.log(InternetComboObj) }}>LOG</button> */}

      <Grid item xs={12} sm={12} sx={{ padding: '10px', borderRadius: '10px' }}>
        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'bold', fontSize: '64px', letterSpacing: '-2.56px', lineHeight: '1'}}>
          Ótima escolha!
        </Typography>
      </Grid>
      <Grid container >

        <CardInternetSelecionado />

        <Box sx={{ width: '100%', marginY: 6 }}>
          <SimpleBackdrop open={open} />
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length - 1 ? (

            <React.Fragment>

              {feedback === 'contratar' && (
                <ComViabilidade
                  {...InternetComboObj}
                  handleBack={handleBack}
                  contratar={contratar}
                  contratado={contratado}
                />
              )}

              {feedback === 'semViabilidade' && <SemViabilidade />}

              {feedback === 'pj' && <PjFeedback />}

              {feedback === 'possuiVenda' && <PossuiVendaFeedback />}

              {feedback === 'ticketAberto' && <PossuiVendaFeedback />}

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>

          ) : (

            <React.Fragment>

              {(activeStep + 1 === 1) && (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <Endereco handleNext={handleNext} setOpen={setOpen} />
                </Typography>
              )}

              {(activeStep + 1 === 2) && (
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <Cadastro handleFinish={handleFinish} InternetComboObj={InternetComboObj} handleBack={handleBack} />
                </Typography>
              )}

            </React.Fragment>
          )}
        </Box>

      </Grid>
      <Grid item xs={12} sm={12}>
        <Footer />
      </Grid>

    </Container>

  );
};

export default CadastroInternet;
