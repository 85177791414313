import React, { useEffect } from 'react'

import { Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'

import MaskedInput from 'react-text-mask'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import 'dayjs/locale/pt-br';
import { BirthDateValidator, CpfValidator } from '../../../../../../../utils/validators';
import { useContext } from 'react';
import InternetComboModel from '../../../../../../../contexts/InternetComboModel';

import CheckIcon from '@mui/icons-material/Check';

const Cadastro = ({ handleFinish, handleBack }) => {

    const [ErrorMessage, setErrorMessage] = React.useState();
    const [isChecked, setIsChecked] = React.useState(true);
    const { InternetComboObj, setInternetComboObj } = useContext(InternetComboModel)

    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,];
    const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

    const handleCheckboxChange = () => { setIsChecked(!isChecked); };

    useEffect(() => {

        if (InternetComboObj.step === "handleContinueCadastro") {
            handleFinish()
        }

    }, [InternetComboObj]);

    const aoSalvar = async (evento) => {

        evento.preventDefault();

        const data = new FormData(evento.currentTarget);

        const correctDatas = await validateDatas(data)
        console.log(correctDatas)

        if (correctDatas) {
            await setInternetComboObj(prevState => ({
                ...prevState,
                logradouro: data.get('rua'),
                numeroCasa: data.get('numero'),
                complemento: data.get('complemento'),
                bairro: data.get('bairro'),
                tipo_pessoa: 'fisica',
                documento: data.get('documento'),
                dataNasc: data.get('dataNascFund'),
                cupom: data.get('cupom'),
                observacao: data.get('observacao'),
                recebimentoFatura: data.get('recebimentoFatura'),
                aceite: isChecked,
                email: data.get('email'),
                step: "handleContinueCadastro",
            }));
        }

    }

    const validateDatas = async (data) => {
        var res = await CpfValidator(data.get('documento'));

        if (res) {

            var dateVal = await BirthDateValidator(data.get('dataNascFund'));

            if (dateVal) {
                setErrorMessage('')
                return true;

            } else {
                setErrorMessage("Data inválida.")
                return false;

            }

        } else {
            setErrorMessage("Documento inválido")
            return false

        }
    }

    const [buttonHovered, setButtonHovered] = React.useState(false);
    const [buttonHoveredVoltar, setButtonHoveredVoltar] = React.useState(false);

    const sxButton = {
        borderRadius: '32px',
        padding: '2px 2px 2px 14px',
        backgroundColor: buttonHovered ? 'white !important' : '#1b1464 !important',
        color: buttonHovered ? '#1b1464 !important' : 'white !important',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#EBEBEB  !important',
            color: '#1b1464 !important',
            '& $arrowIcon': {
                transform: 'rotate(0deg) !important',
            },
        },
    }

    const sxIcon = {
        marginLeft: '8px',
        padding: '8px',
        borderRadius: '50%',
        backgroundColor: buttonHovered ? '#1b1464 !important' : 'white !important',
        color: buttonHovered ? 'white !important' : '#1b1464 !important',
        transform: `rotate(${buttonHovered ? '0deg' : '-45deg'}) !important`,
        transition: 'transform 0.3s',
    }

    const sxButtonVoltar = {
        borderRadius: '32px',
        padding: '2px 14px 2px 2px',
        backgroundColor: '#ebebeb !important',
        color: '#6c757d !important',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#EBEBEB  !important',
            color: '#6c757d !important',
            '& $arrowIcon': {
                transform: 'rotate(0deg) !important',
            },
        },
    }

    const sxIconVoltar = {
        padding: '8px',
        borderRadius: '50%',
        backgroundColor: buttonHoveredVoltar ? '#6c757d !important' : 'white !important',
        color: buttonHoveredVoltar ? 'white !important' : '#6c757d !important',
        transform: `rotate(${buttonHoveredVoltar ? '-180deg' : '-120deg'}) !important`,
        transition: 'transform 0.3s',
    }

    return (
        <>
            <Box sx={{ mt: 4 }}>
                <Grid container justifyContent="center" sx={{ mt: 1, paddingLeft: { xs: 3, sm: 0 }, backgroundColor: '#ebebeb', width: { sm: '40vw', xs: '100%' }, height: '10vh', borderRadius: '50px', margin: 'auto' }}>
                    <Grid item xs={12} sm={3} sx={{ display: { xs: 'none', sm: 'block' }, my: 'auto', textAlign: 'right' }}>
                        <CheckIcon sx={{ color: '#6c757d', fontSize: '38px', mr: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={9} sx={{ my: 'auto' }}>
                        <Typography sx={{ textAlign: 'left', fontSize: '20px', color: '#6c757d', margin: '0', lineHeight: '1' }}>
                            Maravilha.
                        </Typography>
                        <Typography sx={{ textAlign: 'left', fontSize: '20px', color: '#6c757d', margin: '0', lineHeight: '1' }}>
                            Atendemos seu endereço!
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <form onSubmit={aoSalvar} id='form-new'>

                <Typography sx={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', color: '#343a40', margin: '0', lineHeight: '1', mt: 4 }}>
                    Complete seu cadastro
                </Typography>

                <Grid container sx={{ mt: 4 }} >

                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <MaskedInput mask={cpfMask} label={'CPF'} name="documento"
                            defaultValue={InternetComboObj.documento ? InternetComboObj.documento : null} id="my-input-id" guide={false}
                            render={(ref, props) => (
                                <TextField variant="outlined" required fullWidth inputRef={ref} {...props} sx={{ '& fieldset': { borderRadius: 33 } }} />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <MaskedInput mask={dateMask} label={'Data de Nascimento'} name="dataNascFund"
                            defaultValue={InternetComboObj.dataNasc ? InternetComboObj.dataNasc : null} id="my-input-id" guide={false}
                            render={(ref, props) => (
                                <TextField variant="outlined" required fullWidth inputRef={ref} {...props} sx={{ '& fieldset': { borderRadius: 33 } }} />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ padding: '10px' }}>
                        <TextField label="Cupom Amigo Tri" type="text" name="cupom" variant="outlined"
                            defaultValue={InternetComboObj.cupom ? InternetComboObj.cupom : null} fullWidth sx={{ '& fieldset': { borderRadius: 33 } }} />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ padding: '10px' }}>
                        <TextField label="Email" type="Email" name="email" variant="outlined" fullWidth required
                            defaultValue={InternetComboObj.email ? InternetComboObj.email : null} sx={{ '& fieldset': { borderRadius: 33 } }} />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ padding: '10px', mt: 1 }}>
                        <TextField id="outlined-multiline-flexible" label="Em qual turno você prefere ser atendido?" name="observacao" multiline
                            defaultValue={InternetComboObj.observacao ? InternetComboObj.observacao : null}
                            required maxRows={4} fullWidth sx={{ '& fieldset': { borderRadius: 33 } }} />
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="recebimento-fatura-label">Receber a Fatura por:</InputLabel>
                            <Select labelId="recebimento-fatura-label" id="recebimento-fatura" label="Receber a Fatura por:"
                                name="recebimentoFatura" defaultValue="whatsapp" sx={{ '& fieldset': { borderRadius: 33 } }} >
                                <MenuItem value="whatsapp">Whatsapp + Email</MenuItem>
                                <MenuItem value="email">Somente Email</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>

                <Typography sx={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', color: '#343a40', margin: '0', lineHeight: '1', mt: 4 }}>
                    Confirme o endereço de instalação
                </Typography>

                <Grid container sx={{ mt: 4 }} >

                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <TextField label='Cidade' type="text" name="cidade" variant="outlined" disabled required fullWidth
                            defaultValue={InternetComboObj.cidade ? InternetComboObj.cidade : null} sx={{ '& fieldset': { borderRadius: 33 } }} />
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ padding: '10px' }}>
                        <TextField label='Bairro' type="text" name="bairro" variant="outlined" required fullWidth
                            defaultValue={InternetComboObj.bairro ? InternetComboObj.bairro : null} sx={{ '& fieldset': { borderRadius: 33 } }} />
                    </Grid>

                    <Grid item xs={12} sm={8} sx={{ padding: '10px' }}>
                        <TextField label='Rua' type="text" name="rua" variant="outlined" required fullWidth
                            defaultValue={InternetComboObj.logradouro ? InternetComboObj.logradouro : null} sx={{ '& fieldset': { borderRadius: 33 } }} />
                    </Grid>

                    <Grid item xs={12} sm={4} sx={{ padding: '10px' }}>
                        <TextField label='Numero' type="number" name="numero" variant="outlined" required fullWidth
                            defaultValue={InternetComboObj.numeroCasa ? InternetComboObj.numeroCasa : null} sx={{ '& fieldset': { borderRadius: 33 } }} />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ padding: '10px' }}>
                        <TextField label="Complemento" type="text" name="complemento" variant="outlined" fullWidth
                            defaultValue={InternetComboObj.complemento ? InternetComboObj.complemento : null}
                            inputProps={{ maxLength: 50 }} sx={{ '& fieldset': { borderRadius: 33 } }} />
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ padding: '10px', mt: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={isChecked} onChange={handleCheckboxChange} name="whatsappCheckbox" />
                            }
                            label={
                                <span>
                                    Aceito{' '}
                                    <a href="https://copreltelecom.com.br/documentos" target="_blank" rel="noopener noreferrer">
                                        termos de contrato
                                    </a>
                                </span>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} sx={{ padding: '10px', mt: 1 }}>
                        {ErrorMessage &&
                            <Typography sx={{ textAlign: 'center', fontSize: 18, color: 'red' }}>
                                {ErrorMessage}
                            </Typography>
                        }
                    </Grid>

                </Grid>

                <Grid container justifyContent="center" >
                    <Grid item xs={0} sm={3} sx={{ padding: '10px', mt: 1, display: { xs: 'none', sm: 'block' }, }}></Grid>
                    <Grid item xs={10} sm={3} sx={{ padding: '10px', mt: 1 }}>
                        <Button
                            variant="contained"
                            sx={sxButtonVoltar}
                            onMouseEnter={() => setButtonHoveredVoltar(true)}
                            onMouseLeave={() => setButtonHoveredVoltar(false)}
                            onClick={handleBack}
                            fullWidth
                        >
                            <IconButton
                                className="arrowIcon"
                                onClick={handleBack}
                                sx={sxIconVoltar}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }} type='submit'>
                                Voltar
                            </Typography>


                        </Button>
                    </Grid>
                    <Grid item xs={10} sm={3} sx={{ padding: '10px', mt: 1 }}>
                        <Button
                            variant="contained"
                            sx={sxButton}
                            onMouseEnter={() => setButtonHovered(true)}
                            onMouseLeave={() => setButtonHovered(false)}
                            fullWidth
                            type='submit'
                        >
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }} >
                                Continuar
                            </Typography>

                            <IconButton
                                className="arrowIcon"
                                type='submit'
                                sx={sxIcon}
                            >
                                <ArrowForwardIcon />
                            </IconButton>
                        </Button>
                    </Grid>
                    <Grid item xs={0} sm={3} sx={{ display: { xs: 'none', sm: 'block' }, padding: '10px', mt: 1 }}></Grid>
                </Grid>

            </form>
        </>
    )
}

export default Cadastro
