const CpfValidator = async (numerosDoCPF) => {

    // Remover pontos e traço
    const cpf = numerosDoCPF.replace(/\D/g, '');

    if (!/^\d{11}$/.test(cpf)) {
        return false;
    }

    // Verificar CPFs com todos os dígitos iguais
    if (/^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    // Calcular os dígitos verificadores
    const digits = cpf.split('').map(Number);
    const [a, b, c, d, e, f, g, h, i] = digits;

    const validateDigit = (numeros, peso) => {
        const total = numeros.reduce((acc, numero, index) => {
            return acc + numero * (peso - index);
        }, 0);
        const resto = total % 11;
        return resto < 2 ? 0 : 11 - resto;
    };

    const calculatedA = validateDigit([a, b, c, d, e, f, g, h, i], 10);
    const calculatedB = validateDigit([a, b, c, d, e, f, g, h, i, calculatedA], 11);

    return cpf === [a, b, c, d, e, f, g, h, i, calculatedA, calculatedB].join('');
    
};

const BirthDateValidator = (dateOfBirth) => {
    
    // Verificar se a data de nascimento está no formato dd/mm/aaaa
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

    const match = dateRegex.exec(dateOfBirth);
    
    if (!match) {
        return false;
    }
    
    const [, day, month, year] = match;

    // Converter os componentes para números inteiros
    const dayInt = parseInt(day, 10);
    const monthInt = parseInt(month, 10);
    const yearInt = parseInt(year, 10);

    // Verificar se o ano está em um intervalo razoável (por exemplo, entre 1900 e o ano atual)
    const currentYear = new Date().getFullYear();
    if (yearInt < 1900 || yearInt > currentYear) {
        return false;
    }

    // Verificar se o mês e o dia são válidos
    if (monthInt < 1 || monthInt > 12 || dayInt < 1 || dayInt > 31) {
        return false;
    }

    // Verificar datas que não existem, como 31 de fevereiro ou 30 de fevereiro
    if ((monthInt === 2 && dayInt > 29) ||
        ((monthInt === 4 || monthInt === 6 || monthInt === 9 || monthInt === 11) && dayInt > 30)) {
        return false;
    }

    // Se todas as verificações passarem, a data é válida
    return true;
};

export {
    CpfValidator,
    BirthDateValidator
}