export const addresssStringToObj = (address) => {

    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        const endereco_fragmentado = {};

        geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    endereco_fragmentado['lat'] = results[0].geometry.location.lat()
                    endereco_fragmentado['lng'] = results[0].geometry.location.lng()
                    results[0].address_components.forEach(componente => {
                        componente.types.forEach(tipo => { endereco_fragmentado[tipo] = componente.long_name; });
                    });
                    resolve(endereco_fragmentado);
                } else {
                    reject('Nenhum resultado encontrado');
                }
            } else {
                reject(`Erro ao geocodificar o endereço: ${status}`);
            }
        });

    });

};