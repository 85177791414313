import { Box, Button, Container, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const ComViabilidade = ({ nome, telefone, dataNasc, email, documento, estado, cidade, bairro, logradouro, numeroCasa, handleBack, contratar, contratado }) => {


    const [buttonHovered, setButtonHovered] = React.useState(false);
    const [buttonHoveredVoltar, setButtonHoveredVoltar] = React.useState(false);

    const sxButton = {
        borderRadius: '32px',
        padding: '2px 2px 2px 14px',
        backgroundColor: buttonHovered ? 'white !important' : '#1b1464 !important',
        color: buttonHovered ? '#1b1464 !important' : 'white !important',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#EBEBEB  !important',
            color: '#1b1464 !important',
            '& $arrowIcon': {
                transform: 'rotate(0deg) !important',
            },
        },
    }

    const sxIcon = {
        marginLeft: '8px',
        padding: '8px',
        borderRadius: '50%',
        backgroundColor: buttonHovered ? '#1b1464 !important' : 'white !important',
        color: buttonHovered ? 'white !important' : '#1b1464 !important',
        transform: `rotate(${buttonHovered ? '0deg' : '-45deg'}) !important`,
        transition: 'transform 0.3s',
    }

    const sxButtonVoltar = {
        borderRadius: '32px',
        padding: '2px 14px 2px 2px',
        backgroundColor: '#ebebeb !important',
        color: '#6c757d !important',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: '#EBEBEB  !important',
            color: '#6c757d !important',
            '& $arrowIcon': {
                transform: 'rotate(0deg) !important',
            },
        },
    }

    const sxIconVoltar = {
        padding: '8px',
        borderRadius: '50%',
        backgroundColor: buttonHoveredVoltar ? '#6c757d !important' : 'white !important',
        color: buttonHoveredVoltar ? 'white !important' : '#6c757d !important',
        transform: `rotate(${buttonHoveredVoltar ? '-180deg' : '-120deg'}) !important`,
        transition: 'transform 0.3s',
    }

    return (
        <>
            {(contratado === false) ?

                <>
                   <Grid item xs={12} sm={12} sx={{ padding: '10px', borderRadius: '10px', mt: 2 }}>
                        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'bold', fontSize: '22px' }}>
                            Tudo certo {nome}!
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ borderRadius: '10px', mt: 1, marginX: 'auto' }}>
                        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'normal', fontSize: '20px' }}>
                            Verifique todos os seus dados antes de finalizar a contratação.
                        </Typography>
                    </Grid>

                    <Grid container sx={{ mt: 4 }} >
                    <Grid item xs={0} sm={2} sx={{ padding: '10px', mt: 1 }}></Grid>
                        <Grid item xs={12} sm={4} sx={{ padding: '10px' }}>
                            <Typography sx={{ mb: 3, textAlign: 'left', fontSize: 16, color: '#343A40' }}>
                                Nome: <strong>{nome}</strong>. <br />
                                Data de Nascimento: <strong>{dataNasc}</strong>. <br />
                                CPF/CNPJ: <strong>{documento}</strong>. <br />
                                Telefone: <strong>{telefone}</strong>. <br />
                                Email: <strong>{email}</strong>. <br />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ padding: '10px' }}>
                            <Typography sx={{ mb: 3, textAlign: 'left', fontSize: 16, color: '#343A40' }}>
                                Estado: <strong>{estado}</strong>. <br />
                                Cidade: <strong>{cidade}</strong>. <br />
                                Bairro: <strong>{bairro}</strong>. <br />
                                Rua: <strong>{logradouro}</strong>. <br />
                                Numero: <strong>{numeroCasa}</strong>. <br />
                            </Typography>
                        </Grid>
                        <Grid item xs={0} sm={2} sx={{ padding: '10px', mt: 1 }}></Grid>
                    </Grid>

                    <Grid item xs={12} sm={8} sx={{ borderRadius: '10px', mt: 1, marginX: 'auto' }}>
                        <Typography sx={{ color: '#6C757D', textAlign: 'center', fontWeight: 'normal', fontSize: '18px' }}>
                            Ficou com alguma dúvida? Entre em contato <strong> 0800 039 0039 </strong>
                        </Typography>
                    </Grid>

                    <Grid container  >
                        <Grid item xs={0} sm={3} sx={{ padding: '10px', mt: 1 }}></Grid>
                        <Grid item justifyContent="center" xs={12} sm={3} sx={{ padding: '10px', mt: 1 }}>
                            <Button
                                variant="contained"
                                sx={sxButtonVoltar}
                                onMouseEnter={() => setButtonHoveredVoltar(true)}
                                onMouseLeave={() => setButtonHoveredVoltar(false)}
                                onClick={handleBack}
                                fullWidth
                            >
                                <IconButton className="arrowIcon" sx={sxIconVoltar}>
                                    <ArrowForwardIcon />
                                </IconButton>
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }} type='submit'>
                                    Voltar
                                </Typography>
                            </Button>
                        </Grid>
                
                        <Grid item xs={12} sm={3} sx={{ padding: '10px', mt: 1 }}>
                            <Button
                                variant="contained"
                                sx={sxButton}
                                onMouseEnter={() => setButtonHovered(true)}
                                onMouseLeave={() => setButtonHovered(false)}
                                onClick={contratar}
                                fullWidth
                            >
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }} >
                                    Contratar
                                </Typography>
                                <IconButton className="arrowIcon" sx={sxIcon} onClick={contratar}>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Button>
                        </Grid>
                        <Grid item xs={0} sm={3} sx={{ padding: '10px', mt: 1 }}></Grid>
                    </Grid>

                    {/* <Grid container sx={{ mt: 4, mb: 2 }} >
                        <Grid item xs={12} sm={8} sx={{ padding: '10px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button variant="contained" sx={{ mb: 2, fontSize: '18px' }} fullWidth onClick={contratar}>
                                    Contratar
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ padding: '10px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button variant="contained" sx={{ mb: 2, fontSize: '18px' }} fullWidth onClick={handleBack}>
                                    Voltar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid> */}
                </>

                :

                <Container component="main" >

                    <Grid item xs={12} sm={12} sx={{ padding: '10px', borderRadius: '10px', mt: 2 }}>
                        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'bold', fontSize: '22px' }}>
                            Tudo certo!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{ borderRadius: '10px', mt: 1, marginX: 'auto' }}>
                        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'normal', fontSize: '20px' }}>
                            Sua conexão com o futuro está garantida.
                        </Typography>
                        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'normal', fontSize: '20px' }}>
                            A Coprel Telecom leva até você uma internet de alta velocidade e confiabilidade.
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ padding: '10px', borderRadius: '10px', mt: 2 }}>
                        <Typography sx={{ color: '#1B1464', textAlign: 'center', fontWeight: 'bold', fontSize: '22px' }}>
                            Navegue, explore e desfrute.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} sx={{ borderRadius: '10px', mt: 1, marginX: 'auto' }}>
                        <Typography sx={{ color: '#6C757D', textAlign: 'center', fontWeight: 'normal', fontSize: '18px' }}>
                            Ficou com alguma dúvida? Entre em contato <strong> 0800 039 0039 </strong>
                        </Typography>
                    </Grid>
                </Container>
            }
        </>
    )
}

export default ComViabilidade

